import { PricingTable, PricingTableContainer } from "components/pricing-table"

import Accordion from "react-bootstrap/Accordion"
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"
import Layout from "components/layout"
import React from "react"
import SEO from "components/seo"
import useTranslations from "components/use-translations"

export default function Magaza() {
  const {
    magaza: {
      seoTitle,
      seoDescription,
      kutuphaneProgramiPaketleri,
      temel,
      yil,
      Gun14BoyuncaUcretsiz,
      BarkodOkumaVeYazdirma,
      VeriAktarmaSihirbazi,
      DetayliRaporlama,
      CokluKullaniciIleKullanim,
      SunucuPaylasimi,
      UcretsizBarkodOkuyucuCihaz,
      KurumunuzunAdiLogosuVeIkonu,
      KitapListesiPaylasimEkrani,
      SanalDestekTelefon,
      sikSorulanSorular,
      sinirsiz,
      soru1,
      cevap1,
      soru2,
      cevap2,
      soru3,
      cevap3,
      soru4,
      cevap4,
      soru5,
      cevap5,
      soru6,
      cevap6,
      soru7,
      cevap7,
      soru8,
      cevap8,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/magaza"
        datePublished="2016-08-27T19:51:04+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container className="mb-5">
        <h1 className=" mt-4 font-weight-light ">
          {kutuphaneProgramiPaketleri}
        </h1>
      </Container>
      <div
        className="text-center"
        style={{ paddingBottom: 80, minHeight: 500 }}
      >
        <PricingTableContainer>
          <PricingTable
            header={temel}
            colorName="secondary"
            featured={false}
            price={`399,00 ₺ +kdv/${yil}`}
            href="https://www.kodvizit.com/wp/urun/baslangic-paket"
            amount={`1 ${yil}`}
            includingModules={[
              Gun14BoyuncaUcretsiz,
              BarkodOkumaVeYazdirma,
              VeriAktarmaSihirbazi,
              DetayliRaporlama,
            ]}
            excludingModules={[
              CokluKullaniciIleKullanim,
              SunucuPaylasimi,
              KurumunuzunAdiLogosuVeIkonu,
              KitapListesiPaylasimEkrani,
              SanalDestekTelefon,
            ]}
          />
          <PricingTable
            header="Standart"
            colorName="info"
            featured={true}
            price={`1699,00 ₺ veya 1399,00 ₺ +kdv`}
            href="https://www.kodvizit.com/wp/urun/standart-paket"
            amount={sinirsiz}
            includingModules={[
              Gun14BoyuncaUcretsiz,
              BarkodOkumaVeYazdirma,
              VeriAktarmaSihirbazi,
              DetayliRaporlama,
              CokluKullaniciIleKullanim,
              SunucuPaylasimi,
            ]}
            excludingModules={[
              KurumunuzunAdiLogosuVeIkonu,
              KitapListesiPaylasimEkrani,
              SanalDestekTelefon,
            ]}
          />
          <PricingTable
            header="Süper"
            colorName="success"
            featured={false}
            price={`3399,00 ₺ +kdv`}
            href="https://www.kodvizit.com/wp/urun/super-paket"
            amount={sinirsiz}
            includingModules={[
              Gun14BoyuncaUcretsiz,
              BarkodOkumaVeYazdirma,
              VeriAktarmaSihirbazi,
              DetayliRaporlama,
              CokluKullaniciIleKullanim,
              SunucuPaylasimi,
              KurumunuzunAdiLogosuVeIkonu,
              KitapListesiPaylasimEkrani,
              SanalDestekTelefon,
            ]}
            excludingModules={[]}
          />
        </PricingTableContainer>
      </div>
      <Container className="mb-5">
        <h4>{sikSorulanSorular}</h4>
        <Accordion>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              <strong>{soru1}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body>{cevap1}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              <strong>{soru2}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>{cevap2}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              <strong>{soru3}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Body>{cevap3}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              <strong>{soru4}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Body>{cevap4}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              <strong>{soru5}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Body>{cevap5}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="5">
              <strong>{soru6}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="5">
              <Card.Body>{cevap6}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              <strong>{soru7}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Body>{cevap7}</Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              <strong>{soru8}</strong>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Body>{cevap8}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </Container>
    </Layout>
  )
}
